<template>
	<ast-toolbar-button v-if="unopenedRelatedStudies.length" label="Add Related Study" class="study-dropdown">
		<template #dropdown="{ hide }">
			<ast-toolbar-menu :items="unopenedRelatedStudies" @select="study => addStudy(study.studyId, hide)">
				<template #item="{ item: study }">
					{{ study.studyDateTime | localizeDate({ forceUTC: false }) }} ({{ study.modality }})
				</template>
			</ast-toolbar-menu>
		</template>
	</ast-toolbar-button>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AstToolbarButton from '@components/ToolbarButton'
import AstToolbarMenu from '@components/ToolbarMenu'
export default {
	name: 'ViewerAddRelatedStudy',
	components: {
		AstToolbarButton,
		AstToolbarMenu,
	},
	props: {
		relatedStudies: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState({
			studies: state => state.viewer.studies,
		}),
		unopenedRelatedStudies() {
			return this.relatedStudies.filter((relatedStudy, i) => {
				// remove duplicates of the same studyId
				if (i !== this.relatedStudies.findIndex(s => s.studyId === relatedStudy.studyId)) return false
				// remove studies that are open in the viewer
				return !this.studies.some(openStudy => openStudy.studyId === relatedStudy.studyId)
			})
		},
	},
	methods: {
		...mapActions(['getStudyPartnerViewerVmAsync', 'getStudyViewerVmAsync']),
		addStudy(studyId, hideDropdown) {
			hideDropdown()
			const clinicCode = this.$route.params.clinicCode // Partner routes only
			if (clinicCode) {
				this.getStudyPartnerViewerVmAsync({ ids: studyId, clinicCode })
			} else {
				this.getStudyViewerVmAsync({ ids: studyId })
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.study-dropdown {
	li:hover,
	li:active {
		background: var(--menu-item-hover-bg);
	}
	button {
		min-width: 212px;
		.label {
			justify-self: end;
		}
	}
}

/* we want this dropdown button to have quite a bit of
   touchable padding, so steal some screen real estate
   from thumbnails, but only when there isn't a study
   heading in the middle
*/
.study-dropdown + .study-container span .study .series:first-child {
	padding-top: 0;
}
</style>
